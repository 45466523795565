import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import '../styles/InspectionsPage.css'; 

const NewInspection = () => {
  const [templates, setTemplates] = useState([]);
  const [yards, setYards] = useState([]);
  const [gms, setGms] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [sections, setSections] = useState([]);
  const [responses, setResponses] = useState({});
  const [userId, setUserId] = useState(null);
  const [baseData, setBaseData] = useState({
    date: '',  
    yard_id: '',
    gm_id: '',
    lastVisitDate: '',
    lastVisitRating: '',
    currentVisitRating: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchTemplates();
    fetchYards();
    fetchGms();
    fetchUserId();
  }, []);

  // Fetch the current user's ID
  const fetchUserId = async () => {
    const { data: { user }, error } = await supabase.auth.getUser();
    if (error) {
      console.error("Error fetching user:", error);
      setError("Error fetching user information.");
      return;
    }
    if (user) {
      setUserId(user.id);
    }
  };

  // Fetch available templates from the database
  const fetchTemplates = async () => {
    try {
      let { data, error } = await supabase.from('inspection_templates').select('id, name');
      if (error) throw error;
      setTemplates(data);
    } catch (error) {
      setError('Failed to fetch templates.');
      console.error(error);
    }
  };

  // Fetch available yards from the database for the yard dropdown
  const fetchYards = async () => {
    try {
      let { data, error } = await supabase.from('yards').select('id, name');
      if (error) throw error;
      console.log("Yards fetched: ", data); 
      setYards(data);
    } catch (error) {
      setError('Failed to fetch yards.');
      console.error(error);
    }
  };

  // Fetch available GMs (employees with position GM) from the database for the GM dropdown
  const fetchGms = async () => {
    try {
      let { data, error } = await supabase.from('employees').select('id, name').eq('position', 'GM');
      if (error) throw error;
      console.log("GMs fetched: ", data); 
      setGms(data);
    } catch (error) {
      setError('Failed to fetch GMs.');
      console.error(error);
    }
  };

  // Fetch sections and questions for the selected template
  const fetchSections = async (templateId) => {
    try {
      let { data, error } = await supabase
        .from('sections')
        .select('id, title, questions(id, text)')
        .eq('template_id', templateId);

      if (error) throw error;
      setSections(data);
    } catch (error) {
      setError('Failed to fetch sections and questions.');
      console.error(error);
    }
  };

  // Handle the template selection and set the current date as the default inspection date
  const startInspection = (templateId) => {
    setSelectedTemplate(templateId);
    setBaseData({ ...baseData, date: new Date().toISOString().split('T')[0] }); // Set current date
    fetchSections(templateId); // Fetch sections and questions for the template
  };

  // Handle base data change (date, yard, GM, etc.)
  const handleBaseDataChange = (e) => {
    setBaseData({ ...baseData, [e.target.name]: e.target.value });
  };

  // Handle response selection for each question, now including section_id
  const handleResponseChange = (sectionId, questionId, value) => {
    setResponses({
      ...responses,
      [questionId]: {
        ...responses[questionId],
        response: value,
        section_id: sectionId // Store section_id along with the response
      }
    });
  };

  // Handle photo upload for each question, now including section_id
  const handlePhotoChange = (sectionId, questionId, file) => {
    setResponses({
      ...responses,
      [questionId]: {
        ...responses[questionId],
        image: file,
        section_id: sectionId // Store section_id along with the image
      }
    });
  };

  // Handle form submission (saving responses to the database)
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!baseData.date || !baseData.yard_id || !baseData.gm_id) {
      setError("Please fill out all required fields (date, yard, GM).");
      return;
    }

    if (!selectedTemplate) {
      setError("Please select a template.");
      return;
    }

    try {
      // Construct the inspection data
      const inspectionData = {
        template_id: selectedTemplate,
        user_id: userId,
        date: baseData.date,
        yard_id: baseData.yard_id,  
        gm_id: baseData.gm_id,      
        last_visit_date: baseData.lastVisitDate || null,
        last_visit_rating: baseData.lastVisitRating || null,
        current_visit_rating: baseData.currentVisitRating || null
      };

      // Insert the inspection
      const { data: inspection, error: inspectionError } = await supabase
        .from('inspections')
        .insert([inspectionData])
        .select();

      if (inspectionError) throw inspectionError;

      const inspectionId = inspection[0].id;

      // Insert responses for each question
      for (const questionId in responses) {
        const { response, image, section_id } = responses[questionId];
        let imageUrl = null;

        if (image) {
          const { data: imageData, error: imageError } = await supabase
            .storage
            .from('inspection-images')
            .upload(`${inspectionId}/${questionId}`, image);

          if (imageError) throw imageError;

          imageUrl = imageData?.Key;
        }

        const responseData = { 
          inspection_id: inspectionId, 
          question_id: questionId, 
          response, 
          image_url: imageUrl, 
          section_id,   // Include the section_id here
          user_id: userId 
        };

        const { error: responseError } = await supabase
          .from('responses')
          .insert([responseData]);

        if (responseError) throw responseError;
      }

      setSuccess('Inspection submitted successfully!');
    } catch (error) {
      console.error('Failed to submit inspection:', error);
      setError('Failed to submit inspection.');
    }
  };

  return (
    <div className="inspection-container">
      {!selectedTemplate && (
        <div>
          <h2>Available Inspections</h2>
          <div className="template-list">
            {templates.map((template) => (
              <div key={template.id} className="template-item">
                <span>{template.name}</span>
                <button onClick={() => startInspection(template.id)} className="btn-primary">
                  Start Inspection
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedTemplate && (
        <div className="form-container">
          <h3>Inspection Information</h3>
          <div className="form-section">
            <label>Date:</label>
            <input className="input-field" type="date" name="date" value={baseData.date} onChange={handleBaseDataChange} required />
            <label>Yard:</label>
            <select className="input-field" name="yard_id" value={baseData.yard_id} onChange={handleBaseDataChange} required>
              <option value="">Select Yard</option>
              {yards.map((yard) => (
                <option key={yard.id} value={yard.id}>{yard.name}</option>
              ))}
            </select>
            <label>GM:</label>
            <select className="input-field" name="gm_id" value={baseData.gm_id} onChange={handleBaseDataChange} required>
              <option value="">Select GM</option>
              {gms.map((gm) => (
                <option key={gm.id} value={gm.id}>{gm.name}</option>
              ))}
            </select>
            <label>Last Visit Date:</label>
            <input className="input-field" type="date" name="lastVisitDate" value={baseData.lastVisitDate} onChange={handleBaseDataChange} />
            <label>Last Visit Rating:</label>
            <input className="input-field" type="text" name="lastVisitRating" value={baseData.lastVisitRating} onChange={handleBaseDataChange} />
            <label>Current Visit Rating:</label>
            <input className="input-field" type="text" name="currentVisitRating" value={baseData.currentVisitRating} onChange={handleBaseDataChange} required />
          </div>

          {sections.length > 0 && (
            <form onSubmit={handleSubmit}>
              {sections.map((section) => (
                <div key={section.id} className="form-section">
                  <h3>{section.title}</h3>
                  {section.questions.map((question) => (
                    <div key={question.id} className="question-container">
                      <label>{question.text}</label>
                      <select
                        className="input-field"
                        onChange={(e) => handleResponseChange(section.id, question.id, e.target.value)}
                        required
                      >
                        <option value="">Select a response</option>
                        <option value="Below Expectations">Below Expectations</option>
                        <option value="Meets Expectations">Meets Expectations</option>
                        <option value="Exceeds Expectations">Exceeds Expectations</option>
                      </select>
                      <input
                        className="input-field"
                          type="file"
                          accept="image/*"
                          capture="environment"  /* This opens the back camera by default */
                          onChange={(e) => handlePhotoChange(section.id, question.id, e.target.files[0])}
                     />
                    </div>
                  ))}
                </div>
              ))}
              <button type="submit" className="btn-submit">Submit Inspection</button>
            </form>
          )}
        </div>
      )}

      {/* Error and Success Messages */}
      {error && <p className="error">{error}</p>}
      {success && <p className="success">{success}</p>}
    </div>
  );
};

export default NewInspection;