import React, { createContext, useState, useEffect, useContext } from 'react';
import { supabase } from '../supabaseClient'; // Import Supabase client

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        // Get the current session
        const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
        if (sessionError) {
          console.error('Error fetching session:', sessionError);
          setLoading(false);
          return;
        }

        if (sessionData && sessionData.session && sessionData.session.user) {
          setSession(sessionData.session);
          const { user } = sessionData.session;

          // Fetch the user's role from the users table
          const { data: userData, error: userError } = await supabase
            .from('users')
            .select('role')
            .eq('id', user.id)
            .single();

          if (userError || !userData) {
            console.error('Error fetching user data or no user data found:', userError);
            setLoading(false);
            return;
          }

          // Fetch the role description from the roles table
          const { data: roleData, error: roleError } = await supabase
            .from('roles')
            .select('role')
            .eq('id', userData.role)
            .single();

          if (roleError || !roleData) {
            console.error('Error fetching role data:', roleError);
          } else {
            setUserRole(roleData.role); // Set the user role
          }
        } else {
          console.error('No session found or user is missing');
        }
      } catch (error) {
        console.error('Unexpected error in fetching user role:', error);
      } finally {
        setLoading(false); // Loading complete
      }
    };

    fetchUserRole(); // Fetch role on mount
  }, []);

  // Login function
  const login = async (email, password) => {
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });
    if (error) {
      throw new Error('Login failed');
    }
    setSession(data.session); // Set session on successful login
  };

  // Logout function
  const logout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      throw new Error('Logout failed');
    }
    setSession(null); // Clear session on logout
    setUserRole(null); // Clear user role on logout
  };

  // Reset password function
  const resetPassword = async (email) => {
    const { error } = await supabase.auth.resetPasswordForEmail(email);
    if (error) {
      throw new Error('Failed to send password reset email');
    }
  };

  return (
    <AuthContext.Provider value={{ session, userRole, loading, login, logout, resetPassword }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// Hook to use the Auth context
export const useAuth = () => {
  return useContext(AuthContext);
};