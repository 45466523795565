import React, { useState, useEffect, useRef } from 'react';
import logo from '../assets/images/logo.png';
import '../styles/MobileNavbar.css';

function MobileNavbar() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMobileMenuOpen(false); // Close the menu
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  return (
    <>
      <nav className="mobile-navbar">
        <div className="navbar-logo">
          <img src={logo} alt="JBH Logo" />
        </div>
        <div className="navbar-title">
          <strong>Multi-Point Site Inspection</strong>
        </div>
        <div className="hamburger" onClick={toggleMobileMenu}>
          &#9776;
        </div>
      </nav>

      {isMobileMenuOpen && (
        <div className="mobile-menu" ref={menuRef}>
          <a className="nav-link" href="/new-inspection">New Inspection</a>
          <a className="nav-link" href="/admin">Admin</a>
          <a className="nav-link" href="/create-inspection">Create Inspection</a>
          <a className="nav-link" href="/previous-inspections">Previous Inspections</a>
        </div>
      )}
    </>
  );
}

export default MobileNavbar;