import React from 'react';
import logo from '../assets/images/logo.png'; // Adjust the path if needed
import '../styles/Navbar.css'; // Use the CSS you provided

function DesktopNavbar() {
  return (
    <nav className="navbar">
      <div className="nav-left">
        <a className="nav-link" href="/new-inspection">New Inspection</a>
        <a className="nav-link" href="/admin">Admin</a>
      </div>
      <div className="navbar-logo">
        <img src={logo} alt="JBH Logo" />
      </div>
      <div className="nav-right">
        <a className="nav-link" href="/create-inspection">Create Inspection</a>
        <a className="nav-link" href="/previous-inspections">Previous Inspections</a>
      </div>
    </nav>
  );
}

export default DesktopNavbar;