import React, { useState, useEffect } from 'react';
import { supabase, supabaseAdmin } from '../supabaseClient';
import '../styles/AdminPage.css';

const AdminPage = () => {
  const [users, setUsers] = useState([]);
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newUserRole, setNewUserRole] = useState('');
  const [editingUserId, setEditingUserId] = useState(null); 
  const [editingUserEmail, setEditingUserEmail] = useState('');
  const [editingUserRole, setEditingUserRole] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchUsers(); 
  }, []);

  const fetchUsers = async () => {
    try {
      const { data, error } = await supabase.from('users').select('id, email, role');
      if (error) {
        setError('Failed to fetch users.');
      } else {
        setUsers(data);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users.');
    }
  };

  const addUser = async () => {
    if (!newUserEmail || !newUserRole) {
      setError('Please fill out both the email and role fields.');
      return;
    }

    setError('');
    setSuccess('');

    try {
      const { data: newUser, error: createUserError } = await supabaseAdmin.auth.admin.createUser({
        email: newUserEmail,
        email_confirm: true,
      });

      if (createUserError) {
        throw createUserError;
      }

      await supabase.from('users').insert([{ id: newUser.user.id, email: newUserEmail, role: newUserRole }]);

      fetchUsers(); // Refresh users list
      setNewUserEmail('');
      setNewUserRole('');
      setSuccess('User created successfully!');
    } catch (error) {
      console.error('Error creating user:', error);
      setError('Failed to create user.');
    }
  };

  const startEditingUser = (user) => {
    setEditingUserId(user.id);
    setEditingUserEmail(user.email);
    setEditingUserRole(user.role);
  };

  const cancelEditing = () => {
    setEditingUserId(null);
    setEditingUserEmail('');
    setEditingUserRole('');
  };

  const saveUser = async () => {
    try {
      const { error } = await supabase
        .from('users')
        .update({ email: editingUserEmail, role: editingUserRole })
        .eq('id', editingUserId);

      if (error) {
        throw error;
      }

      fetchUsers(); // Refresh the list after editing
      cancelEditing(); // Clear the editing state
      setSuccess('User updated successfully!');
    } catch (error) {
      console.error('Error updating user:', error);
      setError('Failed to update user.');
    }
  };

  const deleteUser = async (userId) => {
    try {
      const { error: deleteUserError } = await supabaseAdmin.auth.admin.deleteUser(userId);
      if (deleteUserError) {
        throw deleteUserError;
      }

      await supabase.from('users').delete().eq('id', userId);

      fetchUsers(); // Refresh the list after deletion
      setSuccess('User deleted successfully!');
    } catch (error) {
      console.error('Error deleting user:', error);
      setError('Failed to delete user.');
    }
  };

  return (
    <div className="admin-container">
      <div className="admin-box">
        <h2>Admin Panel</h2>
        
        {/* Create New User Form */}
        <div className="form-group">
          <h3>Create New User</h3>
          <input
            type="email"
            placeholder="Email"
            value={newUserEmail}
            onChange={(e) => setNewUserEmail(e.target.value)}
            className="form-control"
          />
          <select
            value={newUserRole}
            onChange={(e) => setNewUserRole(e.target.value)}
            className="form-control"
          >
            <option value="">Select Role</option>
            <option value="Admin">Admin</option>
            <option value="User">User</option>
          </select>
          <button onClick={addUser} className="btn-primary">Add User</button>
        </div>

        {/* Error and Success Messages */}
        {error && <p className="error-text">{error}</p>}
        {success && <p className="success-text">{success}</p>}

        {/* Users Table */}
        <div className="table-container">
          <h3>Users List</h3>
          <table className="users-table">
            <thead>
              <tr>
                <th>Email</th>
                <th>Role</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>
                    {editingUserId === user.id ? (
                      <input
                        type="email"
                        value={editingUserEmail}
                        onChange={(e) => setEditingUserEmail(e.target.value)}
                      />
                    ) : (
                      user.email
                    )}
                  </td>
                  <td>
                    {editingUserId === user.id ? (
                      <select
                        value={editingUserRole}
                        onChange={(e) => setEditingUserRole(e.target.value)}
                      >
                        <option value="Admin">Admin</option>
                        <option value="User">User</option>
                      </select>
                    ) : (
                      user.role
                    )}
                  </td>
                  <td>
                    {editingUserId === user.id ? (
                      <>
                        <button className="btn-primary" onClick={saveUser}>Save</button>
                        <button className="btn-secondary" onClick={cancelEditing}>Cancel</button>
                      </>
                    ) : (
                      <>
                        <button className="btn-primary" onClick={() => startEditingUser(user)}>Edit</button>
                        <button className="btn-secondary" onClick={() => deleteUser(user.id)}>Delete</button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;