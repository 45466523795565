import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext'; // Use AuthContext
import '../styles/Login.css';  // Custom CSS for this component

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [resetMessage, setResetMessage] = useState('');  // For password reset success message
  const { login, resetPassword } = useAuth(); // Assuming resetPassword is in AuthContext

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      await login(email, password);
      window.location.href = 'new-inspection';  // Redirect after login
    } catch (error) {
      setError('Login failed. Please check your credentials.');
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault(); // Prevent the default link behavior (navigation)

    if (!email) {
      setError('Please enter your email to reset your password.');
      return;
    }

    try {
      await resetPassword(email);  // Call resetPassword from AuthContext
      setResetMessage('A password reset link has been sent to your email.');
      setError('');  // Clear previous error
    } catch (error) {
      setError('Failed to send password reset email. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>JBH Multi-Point Site Inspection</h2>
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="form-control"
            />
          </div>
          <button type="submit" className="btn-primary">Login</button>
          {error && <p className="error-text">{error}</p>}
          {resetMessage && <p className="success-text">{resetMessage}</p>}
        </form>

        {/* Password Reset Link */}
        <div className="password-reset">
          <a href="#" onClick={handlePasswordReset} className="forgot-password-link">
            Forgot Password?
          </a>
        </div>
      </div>
    </div>
  );
};

export default Login;