import React from 'react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from '@react-pdf/renderer';

// Import the logo correctly
import logo from '../assets/images/logo.png';

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 10,
    fontFamily: 'Helvetica',
  },
  headerContainer: {
    flexDirection: 'row',
    marginBottom: 8, // Reduced margin for tighter rows
    borderWidth: 1,
    borderColor: '#000',
    borderRadius: 5,
  },
  logoContainer: {
    width: '20%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2, // Reduced padding for the logo container
  },
  logo: {
    width: '80%',
    height: 'auto',
  },
  headerInfoContainer: {
    flexDirection: 'column',
    width: '65%',
    justifyContent: 'space-around',
    padding: 2, // Reduced padding for header info
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 2, // Reduced margin for tighter rows
  },
  headerField: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 3, // Reduced margin
    paddingVertical: 2, // Reduced vertical padding for header fields
  },
  headerLabel: {
    fontWeight: 'bold',
    fontSize: 10,
    color: '#444',
  },
  headerValue: {
    fontSize: 10,
    marginLeft: 3, // Reduced space between label and value
    color: '#000',
  },
  currentRatingContainer: {
    width: '15%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2, // Reduced padding for current rating section
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    backgroundColor: '#fe2400',
    color: '#fff',
  },
  currentRatingHeader: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 10,
    marginBottom: 1, // Reduced margin for current rating header
  },
  currentRatingText: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 11,
  },
  sectionTitle: {
    fontSize: 12, // Slightly reduced font size
    fontWeight: 'bold',
    backgroundColor: '#444',
    color: '#fff',
    padding: 4, // Reduced padding for section title
    marginBottom: 2,
    marginTop: 6, // Reduced margin
    borderRadius: 5,
  },
  tableContainer: {
    borderWidth: 1,
    borderColor: '#444',
    marginBottom: 6, // Reduced margin for tighter layout
    borderRadius: 5,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderColor: '#ddd',
    padding: 4, // Reduced padding for table rows
  },
  tableHeaderRow: {
    flexDirection: 'row',
    backgroundColor: '#444',
    color: '#fff',
    padding: 4, // Reduced padding for table header
  },
  tableCell: {
    flex: 1,
    fontSize: 10,
    color: '#000',
    padding: 3, // Reduced padding for table cells
  },
  tableHeaderCell: {
    flex: 1,
    fontWeight: 'bold',
    fontSize: 10,
    color: '#fff',
    padding: 3, // Reduced padding for table header cells
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

const InspectionPrint = ({
  inspection,
  inspectionDetails,
  getYardName,
  getInspectorName,
  formatDate,
  groupDetailsBySection,
  lastVisitDate,
  lastVisitRating,
}) => {
  const yardName = getYardName(inspection.yard_id);
  const inspectorName = getInspectorName(inspection.gm_id) || inspection.inspector_email;

  lastVisitDate = lastVisitDate || 'N/A';
  lastVisitRating = lastVisitRating || 'N/A';

  return (
    <Document>
      <Page style={styles.page}>
        {/* Header Section */}
        <View style={styles.headerContainer}>
          {/* Logo */}
          <View style={styles.logoContainer}>
            <Image src={logo} style={styles.logo} />
          </View>

          {/* Header Information */}
          <View style={styles.headerInfoContainer}>
            {/* Row 1 */}
            <View style={styles.headerRow}>
              <View style={styles.headerField}>
                <Text style={styles.headerLabel}>Date:</Text>
                <Text style={styles.headerValue}>{formatDate(inspection.date)}</Text>
              </View>
              <View style={styles.headerField}>
                <Text style={styles.headerLabel}>GM:</Text>
                <Text style={styles.headerValue}>{inspectorName}</Text>
              </View>
              <View style={styles.headerField}>
                <Text style={styles.headerLabel}>Last Visit:</Text>
                <Text style={styles.headerValue}>{lastVisitDate}</Text>
              </View>
            </View>

            {/* Row 2 */}
            <View style={styles.headerRow}>
              <View style={styles.headerField}>
                <Text style={styles.headerLabel}>Yard:</Text>
                <Text style={styles.headerValue}>{yardName}</Text>
              </View>
              <View style={styles.headerField}>
                <Text style={styles.headerLabel}>Inspector:</Text>
                <Text style={styles.headerValue}>{inspectorName}</Text>
              </View>
              <View style={styles.headerField}>
                <Text style={styles.headerLabel}>Last Rating:</Text>
                <Text style={styles.headerValue}>{lastVisitRating}</Text>
              </View>
            </View>
          </View>

          {/* Current Rating Header and Bubble */}
          <View style={styles.currentRatingContainer}>
            <Text style={styles.currentRatingHeader}>Current Rating</Text>
            <Text style={styles.currentRatingText}>{inspection.current_visit_rating || 'N/A'}</Text>
          </View>
        </View>

        {/* Sections */}
        {Object.entries(groupDetailsBySection(inspectionDetails)).map(([sectionTitle, details], index) => (
          <View key={index}>
            {/* Section Title */}
            <Text style={styles.sectionTitle}>{sectionTitle}</Text>

            {/* Table for Questions/Comments/Answers */}
            <View style={styles.tableContainer}>
              {/* Table Header */}
              <View style={styles.tableHeaderRow}>
                <Text style={styles.tableHeaderCell}>Question</Text>
                <Text style={styles.tableHeaderCell}>Comments</Text>
                <Text style={styles.tableHeaderCell}>Answer</Text>
              </View>

              {/* Table Rows */}
              {details.map((detail, idx) => (
                <View key={idx} style={styles.tableRow}>
                  <Text style={styles.tableCell}>{detail.question_text}</Text>
                  <Text style={styles.tableCell}>{detail.response_comments || ''}</Text>
                  <Text style={styles.tableCell}>{detail.response_text}</Text>
                </View>
              ))}
            </View>
          </View>
        ))}

        {/* Page Number */}
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default InspectionPrint;