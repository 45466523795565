import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-modal';
import { supabase } from '../supabaseClient';
import '../styles/PreviousInspections.css';
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import InspectionPrint from '../components/InspectionPrint';

Modal.setAppElement('#root');

const PreviousInspections = () => {
  const [inspections, setInspections] = useState([]);
  const [yards, setYards] = useState([]);
  const [inspectors, setInspectors] = useState([]);
  const [filteredInspections, setFilteredInspections] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedInspection, setSelectedInspection] = useState(null);
  const [inspectionDetails, setInspectionDetails] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
  const [error, setError] = useState('');
  const [expandedSections, setExpandedSections] = useState({});

  useEffect(() => {
    fetchInspections();
    fetchYards();
    fetchInspectors();
  }, []);

  const fetchInspections = async () => {
    try {
      const { data, error } = await supabase.from('inspections').select('*');
      if (error) throw error;
      setInspections(data);
      setFilteredInspections(data);
    } catch (error) {
      setError('Failed to load inspections.');
      console.error(error);
    }
  };

  const fetchYards = async () => {
    try {
      const { data, error } = await supabase.from('yards').select('*');
      if (error) throw error;
      setYards(data);
    } catch (error) {
      setError('Failed to load yards.');
      console.error(error);
    }
  };

  const fetchInspectors = async () => {
    try {
      const { data, error } = await supabase.from('employees').select('*');
      if (error) throw error;
      setInspectors(data);
    } catch (error) {
      setError('Failed to load inspectors.');
      console.error(error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${String(date.getUTCMonth() + 1).padStart(2, '0')}-${String(date.getUTCDate()).padStart(2, '0')}-${date.getUTCFullYear()}`;
  };

  const getYardName = useCallback((yard_id) => {
    const yard = yards.find((y) => y.id === yard_id);
    return yard ? yard.name : 'Unknown Yard';
  }, [yards]);

  const getInspectorName = useCallback((gm_id) => {
    const inspector = inspectors.find((i) => i.id === gm_id);
    return inspector ? inspector.name : 'Unknown Inspector';
  }, [inspectors]);

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    const sortedInspections = [...filteredInspections].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setFilteredInspections(sortedInspections);
    setSortConfig({ key, direction });
  };

  const getSortArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    const filteredData = inspections.filter((inspection) => {
      const yardName = getYardName(inspection.yard_id).toLowerCase();
      const inspectorName = getInspectorName(inspection.gm_id).toLowerCase();
      const currentVisitRating = inspection.current_visit_rating
        ? inspection.current_visit_rating.toLowerCase()
        : '';
      return (
        yardName.includes(searchTerm) ||
        inspectorName.includes(searchTerm) ||
        currentVisitRating.includes(searchTerm)
      );
    });
    setFilteredInspections(filteredData);
  };

  const fetchInspectionDetails = async (inspectionId) => {
    try {
      const { data: responses, error } = await supabase
        .from('responses')
        .select(`
          response,
          comments,
          image_url,
          question:questions!fk_question (
            text,
            type,
            allow_photo,
            allow_comments,
            section:sections!questions_section_id_fkey (
              title
            )
          )
        `)
        .eq('inspection_id', inspectionId);

      if (error) throw error;

      const details = responses.map((response) => ({
        section_title: response.question?.section?.title || 'Unknown Section',
        question_text: response.question?.text || 'Unknown Question',
        response_text: response.response,
        response_comments: response.comments,
        allow_comments: response.question?.allow_comments || false,
        response_image_url: response.image_url,
      }));

      return details;
    } catch (error) {
      setError('Failed to load inspection details.');
      console.error('Error fetching inspection details:', error);
      return [];
    }
  };

  const handleViewDetails = async (inspection) => {
    const details = await fetchInspectionDetails(inspection.id);
    setInspectionDetails(details);
    setSelectedInspection(inspection);

    const initialExpandedSections = {};
    details.forEach((detail) => {
      initialExpandedSections[detail.section_title] = true;
    });
    setExpandedSections(initialExpandedSections);
  };

  const groupDetailsBySection = useCallback((details) => {
    const grouped = {};
    details.forEach((detail) => {
      if (!grouped[detail.section_title]) {
        grouped[detail.section_title] = [];
      }
      grouped[detail.section_title].push(detail);
    });
    return grouped;
  }, []);

  const toggleSection = (sectionTitle) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [sectionTitle]: !prevState[sectionTitle],
    }));
  };

  const DownloadPDFButton = ({ inspection }) => {
    const [loading, setLoading] = useState(false);

    const handleDownload = async () => {
      setLoading(true);
      try {
        const details = await fetchInspectionDetails(inspection.id);
        const doc = (
          <InspectionPrint
            inspection={inspection}
            inspectionDetails={details}
            getYardName={getYardName}
            getInspectorName={getInspectorName}
            formatDate={formatDate}
            groupDetailsBySection={groupDetailsBySection}
          />
        );

        const asPdf = pdf();
        asPdf.updateContainer(doc);
        const blob = await asPdf.toBlob();

        const fileName = `InspectionReport_${formatDate(inspection.date)}_${getYardName(inspection.yard_id)}.pdf`;
        saveAs(blob, fileName);
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
      setLoading(false);
    };

    return (
      <button className="button" onClick={handleDownload} disabled={loading}>
        {loading ? 'Generating PDF...' : 'PDF'}
      </button>
    );
  };

  return (
    <div className="page-container">
      <h2 className="main-heading">Previous Inspections</h2>

      {error && <p className="error">{error}</p>}

      <input
        type="text"
        placeholder="Search by yard, inspector, or rating"
        value={searchTerm}
        onChange={handleSearch}
        className="search-bar"
      />

      <div className="table-responsive">
        <table className="inspections-table">
          <thead>
            <tr>
              <th onClick={() => handleSort('date')}>Date {getSortArrow('date')}</th>
              <th onClick={() => handleSort('yard_id')}>Yard {getSortArrow('yard_id')}</th>
              <th onClick={() => handleSort('gm_id')}>Inspector {getSortArrow('gm_id')}</th>
              <th onClick={() => handleSort('current_visit_rating')}>
                Current Visit Rating {getSortArrow('current_visit_rating')}
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredInspections.length > 0 ? (
              filteredInspections.map((inspection) => (
                <tr key={inspection.id}>
                  <td>{formatDate(inspection.date)}</td>
                  <td>{getYardName(inspection.yard_id)}</td>
                  <td>{getInspectorName(inspection.gm_id)}</td>
                  <td>{inspection.current_visit_rating}</td>
                  <td className="actions">
                    <div className="action-buttons">
                      <button
                        className="button"
                        onClick={() => handleViewDetails(inspection)}
                      >
                        View
                      </button>
                      <DownloadPDFButton inspection={inspection} />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">No inspections found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {selectedInspection && inspectionDetails && (
        <Modal
          isOpen={!!selectedInspection}
          onRequestClose={() => setSelectedInspection(null)}
          contentLabel="Inspection Details"
          className="modal-content"
          overlayClassName="modal-overlay"
        >
          <h3 className="modal-heading">Inspection Details</h3>
          <p>
            <strong>Date:</strong> {formatDate(selectedInspection.date)}
          </p>
          <p>
            <strong>Yard:</strong> {getYardName(selectedInspection.yard_id)}
          </p>
          <p>
            <strong>Inspector:</strong> {getInspectorName(selectedInspection.gm_id)}
          </p>
          <p>
            <strong>Current Visit Rating:</strong>{' '}
            {selectedInspection.current_visit_rating}
          </p>

          {inspectionDetails.length > 0 ? (
            Object.entries(groupDetailsBySection(inspectionDetails)).map(
              ([sectionTitle, details], index) => (
                <div key={index} className="section-container">
                  <h4
                    className={`section-title ${
                      expandedSections[sectionTitle] ? 'expanded' : ''
                    }`}
                    onClick={() => toggleSection(sectionTitle)}
                  >
                    {sectionTitle}
                  </h4>
                  {expandedSections[sectionTitle] &&
                    details.map((detail, idx) => (
                      <div key={idx} className="question-container">
                        <p className="question-text">
                          <strong>Question:</strong> {detail.question_text}
                        </p>
                        <p className="response-text">
                          <strong>Response:</strong> {detail.response_text}
                        </p>
                        {detail.response_comments && (
                          <p className="comments-text">
                            <strong>Comments:</strong> {detail.response_comments}
                          </p>
                        )}
                        {detail.response_image_url && (
                          <img
                            src={detail.response_image_url}
                            alt="Response"
                            className="response-image"
                          />
                        )}
                      </div>
                    ))}
                </div>
              )
            )
          ) : (
            <p>No responses found for this inspection.</p>
          )}

          <PDFDownloadLink
            document={
              <InspectionPrint
                inspection={selectedInspection}
                inspectionDetails={inspectionDetails}
                getYardName={getYardName}
                getInspectorName={getInspectorName}
                formatDate={formatDate}
                groupDetailsBySection={groupDetailsBySection}
              />
            }
            fileName={`InspectionReport_${formatDate(selectedInspection.date)}_${getYardName(selectedInspection.yard_id)}.pdf`}
          >
            {({ blob, url, loading, error }) =>
              loading ? 'Generating PDF...' : <button className="button">Download PDF</button>
            }
          </PDFDownloadLink>

          <button
            className="button close-button"
            onClick={() => setSelectedInspection(null)}
          >
            Close
          </button>
        </Modal>
      )}
    </div>
  );
};

export default PreviousInspections;
