import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import '../styles/CreateTemplate.css';

const CreateTemplate = () => {
  const [templateName, setTemplateName] = useState('');
  const [description, setDescription] = useState('');
  const [sections, setSections] = useState([
    {
      title: '',
      questions: [
        { text: '', type: 'text', allow_photo: false, allow_comments: false },
      ],
    },
  ]);
  const [templates, setTemplates] = useState([]);
  const [editingTemplateId, setEditingTemplateId] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchTemplates();
  }, []);

  // Fetch existing templates
  const fetchTemplates = async () => {
    try {
      const { data, error } = await supabase.from('inspection_templates').select('*');
      if (error) throw error;
      setTemplates(data);
    } catch (error) {
      setError('Failed to fetch templates.');
      console.error(error);
    }
  };

  // Handle section and question changes
  const handleSectionChange = (index, value) => {
    const newSections = [...sections];
    newSections[index].title = value;
    setSections(newSections);
  };

  const handleQuestionChange = (sectionIndex, questionIndex, field, value) => {
    const newSections = [...sections];
    newSections[sectionIndex].questions[questionIndex][field] = value;
    setSections(newSections);
  };

  // Add/remove sections and questions
  const addSection = () => {
    setSections([
      ...sections,
      {
        title: '',
        questions: [
          { text: '', type: 'text', allow_photo: false, allow_comments: false },
        ],
      },
    ]);
  };

  const addQuestion = (sectionIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].questions.push({
      text: '',
      type: 'text',
      allow_photo: false,
      allow_comments: false,
    });
    setSections(newSections);
  };

  const removeSection = (sectionIndex) => {
    const newSections = sections.filter((_, index) => index !== sectionIndex);
    setSections(newSections);
  };

  const removeQuestion = (sectionIndex, questionIndex) => {
    const newSections = [...sections];
    newSections[sectionIndex].questions = newSections[sectionIndex].questions.filter(
      (_, index) => index !== questionIndex
    );
    setSections(newSections);
  };

  // Handle form submission (create or update)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingTemplateId) {
        // Update existing template
        await supabase
          .from('inspection_templates')
          .update({ name: templateName, description })
          .eq('id', editingTemplateId);

        // Delete old sections/questions and insert updated ones
        await supabase.from('sections').delete().eq('template_id', editingTemplateId);
        for (const section of sections) {
          const { data: sectionData } = await supabase
            .from('sections')
            .insert([{ template_id: editingTemplateId, title: section.title }])
            .select();
          const sectionId = sectionData[0].id;
          for (const question of section.questions) {
            await supabase.from('questions').insert([
              {
                section_id: sectionId,
                text: question.text,
                type: question.type,
                allow_photo: question.allow_photo,
                allow_comments: question.allow_comments,
              },
            ]);
          }
        }
        setSuccess('Template updated successfully!');
        setEditingTemplateId(null);
      } else {
        // Create new template
        const { data: template } = await supabase
          .from('inspection_templates')
          .insert([{ name: templateName, description }])
          .select();
        const templateId = template[0].id;

        // Insert sections and questions
        for (const section of sections) {
          const { data: sectionData } = await supabase
            .from('sections')
            .insert([{ template_id: templateId, title: section.title }])
            .select();
          const sectionId = sectionData[0].id;
          for (const question of section.questions) {
            await supabase.from('questions').insert([
              {
                section_id: sectionId,
                text: question.text,
                type: question.type,
                allow_photo: question.allow_photo,
                allow_comments: question.allow_comments,
              },
            ]);
          }
        }
        setSuccess('Template created successfully!');
      }

      // Reset form after submission
      resetForm();
      fetchTemplates(); // Refresh template list
    } catch (error) {
      setError('Failed to submit template.');
      console.error(error);
    }
  };

  // Reset the form
  const resetForm = () => {
    setTemplateName('');
    setDescription('');
    setSections([
      {
        title: '',
        questions: [
          { text: '', type: 'text', allow_photo: false, allow_comments: false },
        ],
      },
    ]);
    setEditingTemplateId(null);
    setError('');
    setSuccess('');
  };

  // Handle editing an existing template
  const handleEdit = async (templateId) => {
    try {
      const { data: templateData, error } = await supabase
        .from('inspection_templates')
        .select(
          `
          id, name, description,
          sections!sections_template_id_fkey (
            id, title,
            questions!questions_section_id_fkey (
              id, text, type, allow_photo, allow_comments
            )
          )
        `
        )
        .eq('id', templateId)
        .single();

      if (error || !templateData) throw error;

      setTemplateName(templateData.name);
      setDescription(templateData.description);
      setSections(
        templateData.sections.map((section) => ({
          title: section.title,
          questions: section.questions.map((q) => ({
            text: q.text,
            type: q.type,
            allow_photo: q.allow_photo,
            allow_comments: q.allow_comments,
          })),
        }))
      );
      setEditingTemplateId(templateId);
    } catch (error) {
      setError('Failed to load template for editing.');
      console.error(error);
    }
  };

  return (
    <div className="page-container">
      <div className="existing-templates-container">
        <h2 className="main-heading">Existing Templates</h2>
        <ul className="template-list">
          {templates.map((template) => (
            <li key={template.id} className="template-item">
              {template.name}
              <button className="btn-secondary" onClick={() => handleEdit(template.id)}>
                Edit
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="create-template-container">
        <h2 className="main-heading">
          {editingTemplateId ? 'Edit Template' : 'Create New Template'}
        </h2>
        <form onSubmit={handleSubmit}>
          <div className="input-field">
            <label>
              Template Name:
              <input
                type="text"
                className="input-field"
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
                required
              />
            </label>
          </div>

          <div className="input-field">
            <label>
              Description:
              <textarea
                className="input-field"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </label>
          </div>

          {sections.map((section, sectionIndex) => (
            <div key={sectionIndex} className="section-container">
              <div className="section-header">
                <h3>Section {sectionIndex + 1}</h3>
                <button
                  type="button"
                  className="btn-danger"
                  onClick={() => removeSection(sectionIndex)}
                >
                  Remove Section
                </button>
              </div>
              <input
                type="text"
                className="input-field"
                placeholder="Section Title"
                value={section.title}
                onChange={(e) => handleSectionChange(sectionIndex, e.target.value)}
                required
              />
              {section.questions.map((question, questionIndex) => (
                <div key={questionIndex} className="question-container">
                  <div className="question-header">
                    <h4>Question {questionIndex + 1}</h4>
                    <button
                      type="button"
                      className="btn-danger"
                      onClick={() => removeQuestion(sectionIndex, questionIndex)}
                    >
                      Remove Question
                    </button>
                  </div>
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Question Text"
                    value={question.text}
                    onChange={(e) =>
                      handleQuestionChange(sectionIndex, questionIndex, 'text', e.target.value)
                    }
                    required
                  />
                  <select
                    className="input-field"
                    value={question.type}
                    onChange={(e) =>
                      handleQuestionChange(sectionIndex, questionIndex, 'type', e.target.value)
                    }
                  >
                    <option value="text">Text</option>
                    <option value="multiple_choice">Multiple Choice</option>
                    <option value="rating">Rating</option>
                    <option value="image">Image</option>
                  </select>
                  <label>
                    Allow Photo:
                    <input
                      type="checkbox"
                      checked={question.allow_photo}
                      onChange={(e) =>
                        handleQuestionChange(
                          sectionIndex,
                          questionIndex,
                          'allow_photo',
                          e.target.checked
                        )
                      }
                    />
                  </label>
                  <label>
                    Allow Comments:
                    <input
                      type="checkbox"
                      checked={question.allow_comments}
                      onChange={(e) =>
                        handleQuestionChange(
                          sectionIndex,
                          questionIndex,
                          'allow_comments',
                          e.target.checked
                        )
                      }
                    />
                  </label>
                  <div className="question-divider"></div>
                </div>
              ))}
              <button
                type="button"
                className="btn-primary"
                onClick={() => addQuestion(sectionIndex)}
              >
                Add Question
              </button>
            </div>
          ))}

          <button type="button" className="btn-primary" onClick={addSection}>
            Add Section
          </button>
          <div className="button-group">
            <button type="submit" className="btn-submit">
              {editingTemplateId ? 'Update Template' : 'Create Template'}
            </button>
            <button type="button" className="btn-cancel" onClick={resetForm}>
              Cancel
            </button>
          </div>
        </form>

        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
      </div>
    </div>
  );
};

export default CreateTemplate;