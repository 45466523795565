import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Login from './pages/Login';
import NewInspection from './pages/NewInspection';
import AdminPage from './pages/AdminPage';
import CreateTemplate from './pages/CreateTemplate';
import PreviousInspections from './pages/PreviousInspections';
import NotFound from './pages/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider, useAuth } from './context/AuthContext';
import DesktopNavbar from './components/DesktopNavbar';  // Desktop navbar
import MobileNavbar from './components/MobileNavbar';    // Mobile navbar
import ResetPassword from './pages/ResetPassword';  // Import the ResetPassword page
import './styles/Navbar.css'; // Use your existing Navbar.css

function Footer() {
  const { session, userRole, logout, loading } = useAuth();

  if (loading) return null;

  return (
    <footer className="footer">
      <p>Welcome {session ? session.user.email : 'Guest'}, you have {userRole ? userRole : 'no'} privileges.</p>
      <button onClick={() => logout()} className="btn-logout">Logout</button>
    </footer>
  );
}

function Layout() {
  const location = useLocation();

  return (
    <>
      {/* Desktop and Mobile Navigation */}
      <DesktopNavbar />
      <MobileNavbar />

      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />  {/* New reset password route */}
        <Route path="/new-inspection" element={<ProtectedRoute><NewInspection /></ProtectedRoute>} />
        <Route path="/admin" element={<ProtectedRoute adminOnly={true}><AdminPage /></ProtectedRoute>} />
        <Route path="/create-inspection" element={<ProtectedRoute adminOnly={true}><CreateTemplate /></ProtectedRoute>} />
        <Route path="/previous-inspections" element={<ProtectedRoute><PreviousInspections /></ProtectedRoute>} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {location.pathname !== '/login' && <Footer />}
    </>
  );
}

function App() {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        navigator.serviceWorker
          .register('/service-worker.js')
          .then((registration) => {
            console.log('Service Worker registered with scope:', registration.scope);
          })
          .catch((error) => {
            console.log('Service Worker registration failed:', error);
          });
      });
    }
  }, []);

  return (
    <AuthProvider>
      <Router>
        <Layout />
      </Router>
    </AuthProvider>
  );
}

export default App;