import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Use AuthContext to access session and role

const ProtectedRoute = ({ children, adminOnly = false }) => {
  const { session, userRole, loading } = useAuth(); // Destructure the values from the context

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!session) {
    return <Navigate to="/login" />;
  }

  // If adminOnly is true, restrict access to users with the 'Admin' role
  if (adminOnly && userRole !== 'Admin') {
    return <Navigate to="/dashboard" />; // Redirect non-admin users to the dashboard
  }

  return children;
};

export default ProtectedRoute;